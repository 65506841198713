import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

import ButtonLink from '../ButtonLink'
import econom from '../../assets/images/econom.jpg'
import standard from '../../assets/images/standard.jpg'
import twoTier from '../../assets/images/two-tier.jpg'

const Wrapper = styled.article`
  background-color: var(--black);
`

const DivStyled = styled.div`
  max-width: 1300px;
  margin: 0 auto 0 auto;
  padding: 8rem 0 8rem 0;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--white);
    text-align: center;
    margin: 0 4rem 3rem 4rem;
    padding: 0;

    @media (max-width: 576px) {
      margin: 0 2rem 3rem 2rem;
    }
  }
`

const DotsWrapper = styled.div`
  background-color: var(--black);

  ul {
    padding: 0;
  }

  li {
    width: 1rem;
    height: 1rem;
    margin: 0 0.6rem 0 0.6rem;
    background-color: rgba(255, 255, 255, 0.1);

    button {
      width: 1rem;
      height: 1rem;
    }

    button:before {
      display: none;
    }
  }

  li.slick-active {
    background-color: var(--yellow);

    button {
      width: 1rem;
      height: 1rem;
    }

    button:before {
      display: none;
    }
  }
`

const settings = {
  className: 'slider variable-width',
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  appendDots: (dots) => (
    <DotsWrapper>
      <ul>{dots}</ul>
    </DotsWrapper>
  ),
}

const SliderWrapper = styled.div`
  background-color: var(--black);
  padding-bottom: 6rem;
  max-width: 1300px;
  margin: 0 auto 0 auto;
`

const SlideStyled = styled.div`
  width: 430px;
  height: 510px;
  padding: 20px;

  @media (max-width: 576px) {
    width: 278px;
    height: 498px;
  }

  .img-container {
    position: relative;
    width: 430px;
    height: 400px;
    max-width: 430px;
    max-height: 400px;
    margin: 0;
    padding: 0;
    overflow: hidden;

    @media (max-width: 576px) {
      width: 278px;
      height: 468px;
    }
  }

  .img-overlay {
    position: absolute;
    top: 46%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    ul {
      padding: 0;
    }

    li {
      list-style: none;
      padding-bottom: 0;
    }
  }

  img {
    width: 430px;
    height: 400px;
    object-fit: cover;
    transition: transform 0.5s ease;

    @media (max-width: 576px) {
      width: 278px;
      height: 468px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  div {
    position: relative;
    margin: 4rem 0 2rem 0;
    padding: 1rem 0;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 2.5rem;
    color: var(--white);
    text-transform: uppercase;
  }

  span {
    position: absolute;
    bottom: 1.5rem;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 15rem;
    color: rgba(255, 255, 255, 0.1);
  }
`
const images = [econom, standard, twoTier]
const title = ['эконом', 'стандарт', 'двухъярусный']
const services = [
  [
    'санузел без душа',
    'холодильник',
    'спутниковое телевидение',
    'уборка номера',
    'питание (завтрак и обед)',
  ],
  [
    'санузел с душем',
    'холодильник',
    'спутниковое телевидение',
    'уборка номера',
    'питание (завтрак и обед)',
  ],
  [
    'санузел без душа',
    'холодильник',
    'спутниковое телевидение',
    'уборка номера',
    'вид на озеро и на базу',
    'доп. размещение 2-х мест',
    'питание (завтрак и обед)',
  ],
]

const CustomSlide = ({ index, ...props }) => (
  <SlideStyled>
    <div className="img-container">
      <div className="img-overlay">
        <ul>
          {services[index].map((v, i) => (
            <li className="subheading" key={i}>
              {v}
            </li>
          ))}
        </ul>
      </div>
      <img src={images[index]} />
    </div>
    <div>
      <span>{`0${index + 1}`}</span>
      {title[index]}
    </div>
  </SlideStyled>
)

const BookRoom = () => (
  <Wrapper>
    <DivStyled>
      <h1>Что мы можем вам предложить?</h1>
      <ButtonLink href="/book-a-room" dark>
        Забронировать номер
      </ButtonLink>
    </DivStyled>
    <SliderWrapper>
      <Slider {...settings}>
        <CustomSlide index={0} />
        <CustomSlide index={1} />
        <CustomSlide index={2} />
      </Slider>
    </SliderWrapper>
  </Wrapper>
)

export default BookRoom
